body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  background-color: #1396ee;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.container {
  width: 80%;
  margin: 50px auto;
}

.button {
  background: #2196f3;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.button:hover {
  background: #0b7dda;
}