.button {
  padding: 2px 5px 1px 1px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #1b29f0;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.button.active {
  background-color: #555;
}
